<template>
  <div class="container">
    <PublicHeader :totalName="$t('trade').cc" />

    <div class="content">
      <div class="detail">
        <div class="left">
          <!-- <div>风险率：0.00%</div> -->
          <div>
            {{ $t('trade').cczsy }}：<span>{{ AllProfitAmount }}</span>
          </div>
        </div>
        <div class="btn1" @click="handleCloseAll">
          {{ $t('trade').yjpc }}
        </div>
      </div>
      <!-- <div class="head">
        <div>
          <div>{{ $t('trade').name }}/</div>
          <div>{{ $t('trade').leixing }}</div>
        </div>

        <div>{{ $t('trade').cb }}/{{ $t('trade').xj }}</div>
        <div>{{ $t('trade').cc }}/{{ $t('trade').yk }}</div>
        <div>{{ $t('trade').zsj }}/{{ $t('trade').zyj }}</div>
        <span>{{ $t('trade').caozuo }}</span>
      </div> -->
      <NoData v-if="list.length === 0" />
      <van-list
        class="list-box"
        ref="list"
        v-else
        v-model="loading"
        :finished="finished"
        loading-text=""
        finished-text=""
        @load="getList"
      >
        <div class="info" v-for="(item, index) in list" :key="index">
          <div class="line">
            <div>
              <p>{{ $t('home').hy }}</p>
              <p>{{ item.ProductName }}</p>
            </div>
            <div>
              <p>{{ $t('trade').leixing }}</p>
              <p>{{ $t('trade')[item.BuyDirection] }}</p>
            </div>
            <div>
              <p>{{ $t('trade').shijian }}</p>
              <p>{{ item.CreateTime | getLocalTime }}</p>
            </div>
            <div>
              <p>{{ $t('trade').jiaoyie }}</p>
              <p>{{ item.TransactionAmount }}</p>
            </div>
            <div>
              <p>{{ $t('trade').bzj }}</p>
              <p>{{ item.BondAmount }}</p>
            </div>
            <div>
              <!-- 杠杆倍数 -->
              <p>{{ $t('trade').gg }}</p>
              <p>{{ item.Multiple }}</p>
            </div>
            <div>
              <p>{{ $t('trade').cb }}</p>
              <p>{{ item.StartPrice }}</p>
            </div>
            <div>
              <!-- 限价 -->
              <p>{{ $t('trade').xj1 }}</p>
              <p>{{ item.Price }}</p>
            </div>

            <div>
              <!-- 持仓 -->
              <p>{{ $t('trade').cc }}</p>
              <p>{{ item.Num }}</p>
            </div>
            <div>
              <p>{{ $t('trade').yk }}</p>
              <p>{{ item.ProfitAmount }}</p>
            </div>
            <div>
              <p>{{ $t('trade').zsj }}</p>
              <p>{{ item.StopLossPrice }}</p>
            </div>
            <div>
              <p>{{ $t('trade').zyj }}</p>
              <p>{{ item.StopSurplusPrice }}</p>
            </div>

            <div>
              <p>{{ $t('trade').caozuo }}</p>
              <p>
                <span class="btn" @click="handleCancle(item.OrderNo)">{{
                  $t('trade').pc
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/noData'
import productApi from '@/api/product'
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    NoData,
    PublicHeader
  },
  data() {
    return {
      positions: [],
      positions2: 0,
      yhcc: 0,
      list: [],
      loading: false,
      finished: false,
      AllProfitAmount: '0.00',
      form: {
        page: 1,
        size: 100,
        productId: null
      }
    }
  },
  mounted() {
    this.form.productId = this.$route.query.id
    this.getList()
  },

  methods: {
    async handleCancle(OrderNo) {
      await productApi.cancelContractOrderClose(OrderNo)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.form.page = 1
        this.finished = false
        this.list = []
        this.getList()
      }, 500)
    },
    async handleCloseAll() {
      await productApi.ContractOrderCloseAll(this.form.productId)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.form.page = 1
        this.finished = false
        this.list = []
        this.getList()
      }, 500)
    },
    async getList() {
      let form = { ...this.form }
      const res = await productApi.ContractOrderWait(form)
      let data = res.Orders
      this.AllProfitAmount = res.AllProfitAmount
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
        console.log(this.list)
        this.list.forEach(res => {
          console.log(res)
          this.positions.push(
            Number(res.Multiple).toFixed(4) *
              Number(res.Price).toFixed(4) *
              Number(res.Num).toFixed(4)
          )
          console.log(this.positions)
          this.positions.forEach(item => {
            this.positions2 += Number(item)
            console.log(this.positions2.toFixed(4))
            this.yhcc = this.positions2.toFixed(4)
          })
        })
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
  // 非兄弟之间的传值,组件销毁前传值
  // beforeDestroy() {
  //   Bus.$emit('val', data)
  // }
}
</script>

<style lang="less" scoped>
.container {
  color: #f2f5ff;
  padding-top: 44px;
  .content {
    .list-box {
      height: calc(100vh - 90px);
      overflow: scroll;
    }
    .info {
      background: #17181e;
      margin-top: 10px;
      padding: 10px 0;

      .line {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      div {
        width: 25vw;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1.5;
      }
      .btn {
        border: 1px solid #d74e5a;
        padding: 1px 3px;
        display: inline-block;
        color: #d74e5a;
      }
    }
    .detail {
      display: flex;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 1.5;
      justify-content: space-between;
      align-items: center;
      span {
        color: #02c289;
      }
      border: 1px solid #1e2a3d;
    }
    .btn1 {
      background: #e143ac;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 34px;
      border-radius: 6px;
    }
  }
  .head {
    display: flex;
    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
  }
}
</style>
